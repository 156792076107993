<template>
    <h1 class="page-header">
        {{ $t( "pages.discover.headlines.pageHeadline" ) }}
    </h1>

    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <dive-icon />
        </n-icon>
        {{ $t( "pages.discover.headlines.discoverScuba" ) }}
    </h3>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba1P1" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba1P2" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba1P3" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba1P4" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba1Age" ) }}<br>
        {{ $t( "pages.discover.paragraphs.discoverScuba1Duration" ) }}
    </p>
    <div class="d-flex flex-row justify-content-end">
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('contact')"
        >
            <n-icon class="me-2">
                <link-icon />
            </n-icon>
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </div>


    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <dive-icon />
        </n-icon>
        {{ $t( "pages.discover.headlines.discoverScuba2" ) }}
    </h3>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba2P1" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.discoverScuba2Age" ) }}<br>
        {{ $t( "pages.discover.paragraphs.discoverScuba2Duration" ) }}
    </p>
    <div class="d-flex flex-row justify-content-end">
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('contact')"
        >
            <n-icon class="me-2">
                <link-icon />
            </n-icon>
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </div>


    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <bubble-icon />
        </n-icon>
        {{ $t( "pages.discover.headlines.bubbleMaker" ) }}
    </h3>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.bubbleMakerP1" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.discover.paragraphs.bubbleMakerAge" ) }}<br>
        {{ $t( "pages.discover.paragraphs.bubbleMakerDuration" ) }}
    </p>
    <div class="d-flex flex-row justify-content-end">
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('contact')"
        >
            <n-icon class="me-2">
                <link-icon />
            </n-icon>
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </div>
</template>

<script>
import { ArrowRight as LinkIcon } from '@vicons/carbon'
import { ScubaDivingFilled as DiveIcon, BubbleChartFilled as BubbleIcon } from '@vicons/material'

export default {
    components: {
        DiveIcon, BubbleIcon, LinkIcon
    },
    methods: {
        linkTo( name ) {
            return this.$helper.routerJumpTo( this, name )
        }
    }
}
</script>

<style scoped>

</style>